import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Button } from 'reactstrap';
import PermissionsPanel from './PermissionsPanel.js';
import styles from './ThemePermissions.module.css';

class ThemePermissions extends React.Component {
    render() {
        return (
            <Form>
                <PermissionsPanel
                    themePermissions={this.props.themePermissions}
                    onGroupPermissionChanged={
                        this.props.onGroupPermissionChanged
                    }
                    onProviderPermissionChanged={
                        this.props.onProviderPermissionChanged
                    }
                />
                <div className={styles.backBtn}>
                    <Button
                        color="secondary"
                        onClick={() => this.props.onBackButtonClicked()}
                    >
                        <FormattedMessage
                            id="Common.Back"
                            defaultMessage="Back"
                            description="Standard Back text"
                        />
                    </Button>
                </div>
                <div className={styles.actionBtn}>
                    <Button
                        color="primary"
                        onClick={() =>
                            this.props.isEditing
                                ? this.props.updateTheme()
                                : this.props.createNewTheme()
                        }
                    >
                        {this.props.isEditing ? (
                            <FormattedMessage
                                id="Common.Update"
                                defaultMessage="Update"
                                description="Standard Update text"
                            />
                        ) : (
                            <FormattedMessage
                                id="Common.Create"
                                defaultMessage="Create"
                                description="Standard Create text"
                            />
                        )}
                    </Button>
                </div>
            </Form>
        );
    }
}

ThemePermissions.propTypes = {
    themePermissions: PropTypes.array.isRequired,
    onGroupPermissionChanged: PropTypes.func.isRequired,
    onProviderPermissionChanged: PropTypes.func.isRequired,
    createNewTheme: PropTypes.func,
    updateTheme: PropTypes.func,
    isEditing: PropTypes.bool.isRequired,
    onBackButtonClicked: PropTypes.func.isRequired
};

export default ThemePermissions;
