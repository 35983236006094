import axios from 'axios';

class ApiHelper {
    static async get(url) {
        return await axios.get(url);
    }

    static async post(url, body, headers) {
        return await axios.post(url, body, { headers: headers });
    }

    static async postJSON(url, body) {
        return await this.post(url, body, {
            'Content-Type': 'application/json'
        });
    }

    static async put(url, body, headers) {
        return await axios.put(url, body, { headers: headers });
    }

    static async putJSON(url, body) {
        return await this.put(url, body, {
            'Content-Type': 'application/json'
        });
    }

    static async deleteRequest(url) {
        return await axios.delete(url);
    }
}
export default ApiHelper;
