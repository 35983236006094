export default class ThemeListing {
    constructor(theme) {
        this.id = theme ? theme.id : '';
        this.name = theme ? theme.name : '';
        // 'Background' colour
        this.overallGeneralBackgroundColour = theme
            ? theme.overallGeneralBackgroundColour
            : '';
        // 'Primary' colour
        this.calendarDayBackgroundColour = theme
            ? theme.calendarDayBackgroundColour
            : '';
        // 'Secondary' colour
        this.calendarSelectedBackgroundColour = theme
            ? theme.calendarSelectedBackgroundColour
            : '';
        // 'Tertiary' colour
        this.overallGeneralTextColour = theme
            ? theme.overallGeneralTextColour
            : '';
        this.customLogoUrl = theme ? theme.customLogoUrl : '';
    }
}
