import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Layout } from './Components/Layout';
import { Home } from './Components/Home';
import NotFound from './Components/NotFound';
import ErrorBoundary from './Components/ErrorBoundary';
import { CreatePage } from './Components/CreatePage';
import { EditPage } from './Components/EditPage';
import './Interceptor.js';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <ErrorBoundary>
                <Layout>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route
                                exact
                                path="/"
                                component={({ history }) => {
                                    return <Home history={history} />;
                                }}
                            />
                            <Route
                                path={'/:resellerId/CreateResellerTheme'}
                                component={({ match, history }) => {
                                    return (
                                        <CreatePage
                                            resellerId={parseInt(
                                                match.params.resellerId,
                                                10
                                            )}
                                            history={history}
                                        />
                                    );
                                }}
                            />
                            <Route
                                path={
                                    '/:resellerId/EditSimpleTheme/:widgetThemeId'
                                }
                                component={({ match, history }) => {
                                    return (
                                        <EditPage
                                            resellerId={parseInt(
                                                match.params.resellerId,
                                                10
                                            )}
                                            widgetThemeId={parseInt(
                                                match.params.widgetThemeId,
                                                10
                                            )}
                                            history={history}
                                        />
                                    );
                                }}
                            />
                            <Route component={NotFound} />
                        </Switch>
                    </BrowserRouter>
                </Layout>
            </ErrorBoundary>
        );
    }
}
