import React from 'react';
import PropTypes from 'prop-types';
import styles from './PanelTitle.module.css';

export default class Panel extends React.Component {
    render() {
        return <span className={styles.cardTitle}>{this.props.title}</span>;
    }
}

Panel.propTypes = {
    text: PropTypes.string
};
