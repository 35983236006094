import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup
} from 'reactstrap';
import PropTypes from 'prop-types';

class DeleteThemeErrorModal extends React.Component {
    render() {
        const closeBtn = (
            <button
                className="close"
                onClick={() => this.props.toggleDeleteThemeErrorModal()}
            >
                &times;
            </button>
        );

        return (
            <Fragment>
                <Modal size={'lg'} centered isOpen={true}>
                    <ModalHeader
                        toggle={() =>
                            this.props.toggleDeleteThemeErrorModal(true)
                        }
                        close={closeBtn}
                    >
                        <FormattedMessage
                            id="Common.Error"
                            defaultMessage="Error"
                            description="Standard Error text"
                        />
                    </ModalHeader>
                    <ModalBody className="d-flex flex-column">
                        <FormGroup>
                            <FormattedMessage
                                id="DeleteThemeErrorModal.ThemeInUseError"
                                description="Error shown when a theme is in use"
                                defaultMessage="The {themeName} theme is in use and can not be deleted"
                                values={{
                                    themeName: this.props.themeName
                                }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            color="primary"
                            onClick={() =>
                                this.props.toggleDeleteThemeErrorModal(true)
                            }
                        >
                            <FormattedMessage
                                id="Common.Close"
                                defaultMessage="Close"
                                description="Standard Close text"
                            />
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

DeleteThemeErrorModal.propTypes = {
    themeName: PropTypes.string,
    toggleDeleteThemeErrorModal: PropTypes.func
};

export default DeleteThemeErrorModal;
