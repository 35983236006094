import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import ColourPicker from './ColourPicker';
import RadiusPicker from './RadiusPicker.js';
import Panel from './Panel.js';
import PanelTitle from './PanelTitle.js';
import PanelRow from './PanelRow.js';

function CalendarPanel(props) {
    const { intl } = props;
    return (
        <Panel header="CALENDAR">
            <PanelRow>
                <Col>
                    <PanelTitle
                        title={intl.formatMessage(
                            definedTranslations.outerTitle
                        )}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="calendarBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={props.theme.calendarBackgroundColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="calendarMonthAndDateTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={
                            props.theme.calendarMonthAndDateTextColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="calendarBorderColour"
                        label={intl.formatMessage(definedTranslations.border)}
                        selectedValue={props.theme.calendarBorderColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow className="subsequentRow">
                <Col sm="4">
                    <RadiusPicker
                        name="calendarBorderRadius"
                        label={intl.formatMessage(
                            definedTranslations.cornerRadius
                        )}
                        maxValue={20}
                        selectedValue={props.theme.calendarBorderRadius}
                        onNumberValueChanged={props.onNumberValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <PanelTitle
                        title={intl.formatMessage(definedTranslations.days)}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="calendarDayBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={props.theme.calendarDayBackgroundColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="calendarDayTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.calendarDayTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <PanelTitle
                        title={intl.formatMessage(
                            definedTranslations.selectedDay
                        )}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="calendarSelectedBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={
                            props.theme.calendarSelectedBackgroundColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="calendarSelectedTextColour"
                        selectedValue={props.theme.calendarSelectedTextColour}
                        label={intl.formatMessage(definedTranslations.text)}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

CalendarPanel.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onNumberValueChanged: PropTypes.func.isRequired
};

export default injectIntl(CalendarPanel);
