import axios from 'axios';

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        switch (error.response.status) {
            case 401:
            case 403:
            case 404:
                return window.location.replace('.././Components/NotFound');
        }
        return Promise.reject(error);
    }
);
