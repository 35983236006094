import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import ColourPicker from './ColourPicker';
import Panel from './Panel.js';
import { Col } from 'reactstrap';
import PanelRow from './PanelRow.js';

function ButtonsPanel(props) {
    const { intl } = props;
    return (
        <Panel header="BUTTONS">
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="overallBookButtonColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={props.theme.overallBookButtonColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="overallBookButtonFontColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.overallBookButtonFontColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

ButtonsPanel.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired
};

export default injectIntl(ButtonsPanel);
