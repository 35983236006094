import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';
import apiHelper from '../Helpers/ApiHelper';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        apiHelper.postJSON('api/Error/JavascriptError', {
            Message: error.message,
            ErrorStack: error.stack,
            ComponentStack:
                info && info.componentStack ? info.componentStack : ''
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Alert color="danger">
                    <FormattedMessage
                        id="Common.Error"
                        description="Standard Error text"
                        defaultMessage="Error"
                    />
                </Alert>
            );
        }

        return this.props.children;
    }
}
