import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input
} from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './UploadLogoModal.module.css';

class UploadLogoModal extends React.Component {
    render() {
        const closeBtn = (
            <Button
                className="close"
                onClick={() => this.props.toggleUploadLogoModal()}
            >
                &times;
            </Button>
        );

        return (
            <Fragment>
                <Modal size={'lg'} centered isOpen={true}>
                    <ModalHeader
                        toggle={() => this.props.toggleUploadLogoModal(true)}
                        close={closeBtn}
                    >
                        <FormattedMessage
                            id="UploadLogoModal.UploadAnImage"
                            defaultMessage="Upload an Image"
                            description="Text for the Upload logo modal"
                        />
                    </ModalHeader>
                    <ModalBody className="d-flex flex-column">
                        <FormGroup>
                            <Input
                                type="file"
                                id="single"
                                accept=".jpg,.jpeg,.png,.gif,.bmp"
                                onChange={this.props.onFileValueChanged}
                            />
                        </FormGroup>
                        <div className={styles.infoText}>
                            {this.props.infoText}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            color="primary"
                            onClick={this.props.toggleUploadLogoModal}
                        >
                            <FormattedMessage
                                id="Common.Cancel"
                                defaultMessage="Cancel"
                                description="Standard Cancel text"
                            />
                        </Button>
                        <Button
                            color="primary"
                            onClick={this.props.toggleUploadLogoModal}
                        >
                            <FormattedMessage
                                id="Common.Upload"
                                defaultMessage="Upload"
                                description="Standard Upload text"
                            />
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

UploadLogoModal.propTypes = {
    infoText: PropTypes.string,
    toggleUploadLogoModal: PropTypes.func,
    onFileValueChanged: PropTypes.func
};

export default UploadLogoModal;
