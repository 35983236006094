import less from 'less';

export default class Theme {
    constructor(theme) {
        this.id = theme ? theme.id : '';
        this.name = theme ? theme.name : '';

        // 'Background' colour
        this.overallGeneralBackgroundColour = theme
            ? theme.overallGeneralBackgroundColour
            : '#000000';
        // 'Tertiary' colour
        this.overallGeneralTextColour = theme
            ? theme.overallGeneralTextColour
            : '#ffffff';
        this.overallGeneralTableReturnColour = theme
            ? theme.overallGeneralTableReturnColour
            : '#f1e1b5';
        this.overallGeneralBorderRadius = theme
            ? theme.overallGeneralBorderRadius
            : 0;
        this.overallGeneralFont = theme
            ? theme.overallGeneralFont
            : 'Open Sans';

        this.overallBookButtonColour = theme
            ? theme.overallBookButtonColour
            : '#f1e1b5';
        this.overallBookButtonFontColour = theme
            ? theme.overallBookButtonFontColour
            : '#ffffff';

        this.bookingSummaryBackgroundColour = theme
            ? theme.bookingSummaryBackgroundColour
            : '#000000';
        this.bookingSummaryTextColour = theme
            ? theme.bookingSummaryTextColour
            : '#ffffff';
        this.bookingSummaryBorderRadius = theme
            ? theme.bookingSummaryBorderRadius
            : 0;
        this.calendarBackgroundColour = theme
            ? theme.calendarBackgroundColour
            : '#000000';
        this.calendarBorderColour = theme
            ? theme.calendarBorderColour
            : '#000000';
        this.calendarMonthAndDateTextColour = theme
            ? theme.calendarMonthAndDateTextColour
            : '#ffffff';
        this.calendarBorderRadius = theme ? theme.calendarBorderRadius : 0;
        this.calendarDayTextColour = theme
            ? theme.calendarDayTextColour
            : '#ffffff';
        this.calendarDayBorderColour = theme
            ? theme.calendarDayBorderColour
            : '#000000';
        // 'Primary' colour
        this.calendarDayBackgroundColour = theme
            ? theme.calendarDayBackgroundColour
            : '#00B7F0';
        this.calendarDayBorderRadius = theme
            ? theme.calendarDayBorderRadius
            : 0;
        this.calendarSelectedTextColour = theme
            ? theme.calendarSelectedTextColour
            : '#ffffff';
        this.calendarSelectedBorderColour = theme
            ? theme.calendarSelectedBorderColour
            : '#000000';
        this.venuesBackgroundColour = theme
            ? theme.venuesBackgroundColour
            : '#0099f1';
        // 'Secondary' colour
        this.calendarSelectedBackgroundColour = theme
            ? theme.calendarSelectedBackgroundColour
            : '#f1e1b5';
        this.citiesBackgroundColour = theme
            ? theme.citiesBackgroundColour
            : '#f1e1b5';
        this.dropdownGeneralBackgroundColour = theme
            ? theme.dropdownGeneralBackgroundColour
            : '#ffffff';
        this.dropdownGeneralTextColour = theme
            ? theme.dropdownGeneralTextColour
            : '#454745';
        this.dropdownGeneralValidationErrorColour = theme
            ? theme.dropdownGeneralValidationErrorColour
            : '#00b7f0';
        this.dropdownGeneralPlaceholderColour = theme
            ? theme.dropdownGeneralPlaceholderColour
            : '#454745';
        this.dropdownBorderColour = theme
            ? theme.dropdownBorderColour
            : '#00b7f0';
        this.dropdownBorderFocusColour = theme
            ? theme.dropdownBorderFocusColour
            : '#00b7f0';
        this.dropdownBorderRadius = theme ? theme.dropdownBorderRadius : 0;
        this.dropdownSelectedBackgroundColour = theme
            ? theme.dropdownSelectedBackgroundColour
            : '#f0f0f0';
        this.dropdownSelectedTextColour = theme
            ? theme.dropdownSelectedTextColour
            : '#454745';
        this.promoBoxBorderColour = theme
            ? theme.promoBoxBorderColour
            : '#000000';
        this.promoBoxBorderRadius = theme ? theme.promoBoxBorderRadius : 0;
        this.promoOptionBackgroundColour = theme
            ? theme.promoOptionBackgroundColour
            : '#000000';
        this.promoOptionTextColour = theme
            ? theme.promoOptionTextColour
            : '#ffffff';
        this.promoOptionHoverBackgroundColour = theme
            ? theme.promoOptionHoverBackgroundColour
            : '#f1e1b5';
        this.promoOptionHoverTextColour = theme
            ? theme.promoOptionHoverTextColour
            : '#ffffff';
        this.promoSelectedBackgroundColour = theme
            ? theme.promoSelectedBackgroundColour
            : '#f1e1b5';
        this.promoSelectedTextColour = theme
            ? theme.promoSelectedTextColour
            : '#ffffff';
        this.venuesTextColour = theme ? theme.venuesTextColour : '#ffffff';
        this.citiesTextColour = theme ? theme.citiesTextColour : '#ffffff';

        // CSS string is initially empty. Set when Theme is created or updated
        this.cssString = '';
        this.customLogoUrl = theme ? theme.customLogoUrl : '';
        this.resdiaryLogo = theme ? theme.resdiaryLogo : 1;
    }

    getResDiaryLogoColour() {
        return this.resdiaryLogo === 2 ? 'black' : 'white';
    }

    async getThemeAsCssString(resDiaryLogoUrl) {
        try {
            var selectedResDiaryLogoUrl = resDiaryLogoUrl.replace(
                'newwidgetlogo1.svg',
                `newwidgetlogo${this.resdiaryLogo}.svg`
            );
            return await less.render(
                this.getThemeAsLessString(selectedResDiaryLogoUrl)
            );
        } catch (error) {
            return error;
        }
    }

    getThemeAsLessString(resDiaryLogoUrl) {
        return `#rd-widget-root, #rd-mini-widget-root {
            .rd-widget-content {
                background-color: ${this.overallGeneralBackgroundColour}; 
                color: ${this.overallGeneralTextColour};
                border-radius: ${this.overallGeneralBorderRadius}px; 
                font-family: ${this.overallGeneralFont};
            }
        
            .general-text {
                color: ${this.overallGeneralTextColour};
            }
        
            .btn-default{ 
                background-color: ${this.overallBookButtonColour}; 
                color: ${this.overallBookButtonFontColour}; 
                border-radius:${this.overallGeneralBorderRadius}px; 
        
                &.disabled,
                &[disabled],
                &.disabled:focus,
                &[disabled]:focus,
                &.disabled:active,
                &[disabled]:active,
                &.disabled:hover,
                &[disabled]:hover {
                    background-color: lighten(${
                        this.overallBookButtonColour
                    },30%);
                    color: lighten(${this.overallBookButtonFontColour},30%);
                }
            }
        
            .view-selected-options{ 
                background-color: ${this.bookingSummaryBackgroundColour}; 
                color: ${this.bookingSummaryTextColour}; 
            }
        
            .panel-booking {  
                border-radius: ${this.bookingSummaryBorderRadius}px;
            }
            
            table.table.table-confirmation {
                tr {
                    background-color: ${this.bookingSummaryBackgroundColour};
                    color: ${this.bookingSummaryTextColour};
                }
            }
        
            .list-group.list-group-promotion {
                .list-group-item {
                    border: 1px solid ${this.promoBoxBorderColour};
                    background-color:${this.promoOptionBackgroundColour};
                    color: ${this.promoOptionTextColour};
        
                    &:first-child {
                        border-top-left-radius: ${this.promoBoxBorderRadius}px;
                        border-top-right-radius: ${this.promoBoxBorderRadius}px;
                    }
        
                    &:last-child {
                        border-bottom-left-radius: ${
                            this.promoBoxBorderRadius
                        }px;
                        border-bottom-right-radius: ${
                            this.promoBoxBorderRadius
                        }px;
                    }
        
                    &.active{
                       color: ${this.promoSelectedTextColour};
                       background-color:${this.promoSelectedBackgroundColour};
                    }
        
        
                    &:hover{
                        background-color: ${
                            this.promoOptionHoverBackgroundColour
                        };
                        color: ${this.promoOptionHoverTextColour};
                    }
                }
            }

            .drop-fill-in{
                color: ${this.dropdownGeneralTextColour}; 
                background-color: ${this.dropdownGeneralBackgroundColour};
                border-radius:${this.dropdownBorderRadius}px; 
                border: 2px solid ${this.dropdownBorderColour};
                
                &.form-control[readonly] {
                    background-color: ${this.dropdownGeneralBackgroundColour}; 
                }
        
                .search-control {
                    border: none;
                    box-shadow: none;
                }
                
                &:focus{ 
                    border:1px solid ${this.dropdownBorderFocusColour}; 
                    box-shadow: 0 0 10px ${this.dropdownBorderFocusColour}; 
                }
            }
        
            .StripeElement--focus {
                box-shadow: 0 0 10px ${this.dropdownBorderFocusColour};
                border: 1px solid ${this.dropdownBorderFocusColour};
            }
            
            .has-error .help-block{ 
                color: ${this.dropdownGeneralValidationErrorColour}; 
            }
            .datepicker-inline{ 
                background-color: ${this.calendarBackgroundColour}; 
                border-radius: ${this.calendarBorderRadius}px; 
                border: 2px solid ${this.calendarBorderColour}; 
                color: ${this.calendarMonthAndDateTextColour}; 
            }
        
            .datepicker-inline-mini {
        
                color: ${this.calendarMonthAndDateTextColour};
        
                .bootstrap-datetimepicker-widget {
                    background-color: ${this.calendarBackgroundColour};
                    border-radius: ${this.calendarBorderRadius}px;
                    border: 2px solid ${this.calendarBorderColour};
                }
            }
        
            .datepicker table td{ 
                border-radius: ${this.calendarDayBorderRadius}px;
            }
            .datepicker table td.active, 
            .datepicker table td.active:hover{ 
                color: ${this.calendarSelectedTextColour}; 
                background-color: ${this.calendarSelectedBackgroundColour}; 
            }
        
            td.day{ 
                color: ${this.calendarDayTextColour}; 
                background-color: ${this.calendarDayBackgroundColour};
            }
        
            .bootstrap-datetimepicker-widget table td.disabled, td.day.old.disabled{ 
                background-color: lighten(${
                    this.calendarDayBackgroundColour
                },30%); 
            }
        
            .bootstrap-datetimepicker-widget table td.disabled:hover, td.day.old.disabled:hover{ 
                background-color: lighten(${
                    this.calendarDayBackgroundColour
                },30%); 
            }
        
            .drop-list { 
                color: ${this.dropdownGeneralTextColour};
                background-color: ${this.dropdownGeneralBackgroundColour};
                
                li { 
                    background-color: ${this.dropdownGeneralBackgroundColour}; 
                }
        
                li:hover { 
                    background-color: ${this.dropdownSelectedBackgroundColour};
                    color: ${this.dropdownSelectedTextColour}; 
                }
        
                .selected, .selected:hover, .selected:focus { 
                    background-color: ${this.dropdownSelectedBackgroundColour}; 
                    color: ${this.dropdownSelectedTextColour}; 
                }
            }
        
            .addCustomLogo(@logo) when not (@logo = '') {
                background: url( ${this.customLogoUrl} ) no-repeat;
                background-size: contain;
                background-position: center;
                width: 200px;
                height: 50px;
            }
        
            .addCustomLogo(@logo) when (@logo = '') {
                display: none;
            }
        
            .custom-logo {
                .addCustomLogo('${this.customLogoUrl}');
            }
        
            .resdiary-chosen-logo, .resdiary-chosen-logo::before, .resdiary-chosen-logo::after {
               content: url('${resDiaryLogoUrl}');
            }
        
            .logo-panel {
                border-radius: ${this.bookingSummaryBorderRadius}px;
		        background-color: ${this.getResDiaryLogoColour()};
            }
        
            .table-return-notice{
                color: ${this.overallGeneralTableReturnColour};
            }
        
            // Placeholder text
            .placeholder(@color) {
                // Firefox
                &::-moz-placeholder {
                    color: @color;
                    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
                }
                &:-ms-input-placeholder { color: @color; } // Internet Explorer 10+
                &::-webkit-input-placeholder  { color: @color; } // Safari and Chrome
            }
        
            .placeholder(${this.dropdownGeneralPlaceholderColour});
        
            .form-control {
                .placeholder(${this.dropdownGeneralPlaceholderColour});
            }
        
            .venue-selector {
                .header {
                    background-color: ${this.overallGeneralBackgroundColour};
                    color: ${this.overallGeneralTextColour};
        
                    .btn {
                        color: ${this.overallGeneralTextColour};
                        background-color: ${
                            this.dropdownGeneralBackgroundColour
                        };
                    }
                }

                .city-header {
                    background-color: ${this.citiesBackgroundColour};
                    color: ${this.citiesTextColour};
                }

                .venue:not(:first-child) {
                    border-top: solid 1px ${this.citiesBackgroundColour};
                }

                .venue {
                    background-color: ${this.venuesBackgroundColour};
                    color: ${this.venuesTextColour};

                    .btn-choose-venue {
                         color: ${this.venuesTextColour};
                     }
                }
        
                .lc-menu {
                    color: ${this.overallGeneralTextColour}; 
                    background-color: ${this.dropdownGeneralBackgroundColour};
                }
            }

            .rd-widget-wrapper {
                display: flex;
                margin-bottom: 20px;

                .wrapper-action-button {
                    margin-left: auto;
                    cursor: pointer;
                    color: ${this.calendarSelectedBackgroundColour};
                }

                .wrapper-username-text {
                    color: ${this.calendarSelectedBackgroundColour};
                }
            }

        }`;
    }
}

// .resdiary-chosen-logo, .resdiary-chosen-logo::before, .resdiary-chosen-logo::after {
//     content: url(@resdiarylogo) ;
//  }

// background-color: @logobackground; (logo panel)
