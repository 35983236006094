import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import ColourPicker from './ColourPicker';
import Panel from './Panel.js';
import PanelRow from './PanelRow.js';

function ColourPanel(props) {
    const { intl } = props;
    return (
        <Panel header="COLOUR">
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="backgroundColours"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={
                            props.theme.overallGeneralBackgroundColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="primaryColours"
                        label={intl.formatMessage(definedTranslations.primary)}
                        selectedValue={props.theme.calendarDayBackgroundColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="secondaryColours"
                        label={intl.formatMessage(
                            definedTranslations.secondary
                        )}
                        selectedValue={
                            props.theme.calendarSelectedBackgroundColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="tertiaryColours"
                        label={intl.formatMessage(definedTranslations.tertiary)}
                        selectedValue={props.theme.overallGeneralTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

ColourPanel.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired
};

export default injectIntl(ColourPanel);
