import apiHelper from './Helpers/ApiHelper';

export async function getWidgetThemeForReseller(resellerId, widgetThemeId) {
    const response = await apiHelper.get(
        `/api/WidgetTheme/Reseller/${resellerId}/WidgetThemeId/${widgetThemeId}`
    );
    return response.data;
}

export async function getWidgetThemesForReseller(resellerId) {
    const response = await apiHelper.get(
        `api/WidgetTheme/Reseller/${resellerId}`
    );
    return response.data;
}

export async function getWidgetThemesForProvider(providerId) {
    const response = await apiHelper.get(
        `api/WidgetTheme/Provider/${providerId}`
    );
    return response.data;
}

export async function getGroupsAndProvidersForReseller(resellerId) {
    const response = await apiHelper.get(
        `api/WidgetTheme/Reseller/${resellerId}/GroupsAndProviders`
    );
    return response.data;
}

export async function getProviderPermissions(resellerId, widgetThemeId) {
    const response = await apiHelper.get(
        `api/WidgetTheme/Reseller/${resellerId}/Permissions/${widgetThemeId}`
    );
    return response.data;
}

export async function createWidgetThemeForReseller(resellerId, themeDto) {
    const response = await apiHelper.postJSON(
        `/api/WidgetTheme/Reseller/${resellerId}`,
        JSON.stringify(themeDto)
    );
    return response.data;
}

export async function uploadLogo(resellerId, imageFile) {
    const formData = new FormData();
    formData.append('customLogo', imageFile);
    const response = await apiHelper.post(
        `/api/WidgetTheme/Reseller/${resellerId}/UploadCustomLogo`,
        formData,
        { 'Content-Type': 'multipart/form-data' }
    );
    return response.data;
}

export async function removeLogo(resellerId, fileName) {
    await apiHelper.deleteRequest(
        `/api/WidgetTheme/Reseller/${resellerId}/RemoveCustomLogo/?fileName=${fileName}`
    );
}

export async function updateWidgetTheme(resellerId, widgetThemeId, themeDto) {
    const response = await apiHelper.putJSON(
        `/api/WidgetTheme/Reseller/${resellerId}/WidgetThemeId/${widgetThemeId}`,
        JSON.stringify(themeDto)
    );
    return response.data;
}

export async function updateProviderPermissions(
    resellerId,
    widgetThemeId,
    permissionDto
) {
    const response = await apiHelper.putJSON(
        `api/WidgetTheme/Reseller/${resellerId}/Permissions/${widgetThemeId}`,
        JSON.stringify(permissionDto)
    );
    return response.data;
}

export async function deleteWidgetTheme(resellerId, widgetThemeId) {
    const response = await apiHelper.deleteRequest(
        `/api/WidgetTheme/Reseller/${resellerId}/WidgetThemeId/${widgetThemeId}`
    );
    return response.data;
}
