import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import ColourPicker from './ColourPicker';
import RadiusPicker from './RadiusPicker.js';
import Panel from './Panel.js';
import { Col } from 'reactstrap';
import PanelRow from './PanelRow.js';

function BookingSummaryPanel(props) {
    const { intl } = props;
    return (
        <Panel header="BOOKING SUMMARY">
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="bookingSummaryBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={
                            props.theme.bookingSummaryBackgroundColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="bookingSummaryTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.bookingSummaryTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <RadiusPicker
                        name="bookingSummaryBorderRadius"
                        label={intl.formatMessage(
                            definedTranslations.cornerRadius
                        )}
                        maxValue={20}
                        selectedValue={props.theme.bookingSummaryBorderRadius}
                        onNumberValueChanged={props.onNumberValueChanged}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

BookingSummaryPanel.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onNumberValueChanged: PropTypes.func.isRequired
};

export default injectIntl(BookingSummaryPanel);
