import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Container, Row } from 'reactstrap';
import { ThemeList } from './ThemeList';
import styles from './Home.module.css';
import queryStringHelper from '../Helpers/QueryStringHelper';
import { Link } from 'react-router-dom';
export class Home extends Component {
    static displayName = Home.name;

    getCreateUrl() {
        const resellerId = queryStringHelper.getParameterByName('resellerId');
        const providerId = queryStringHelper.getParameterByName('providerId');
        if (resellerId) {
            return resellerId + '/CreateResellerTheme';
        }
        if (providerId) {
            return providerId + '/CreateProviderTheme';
        }
        return '';
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <div className={styles.themeListingHeader}>
                        <FormattedMessage
                            id="Home.Title"
                            defaultMessage="Widget Themes"
                            description="Main header of the app's home page"
                            tagName="h1"
                        />
                        <div className={styles.themeListingHeaderButton}>
                            <Link
                                className="btn btn-primary"
                                to={this.getCreateUrl()}
                            >
                                <FormattedMessage
                                    id="Home.NewTheme"
                                    defaultMessage="New Theme"
                                    description="Text to be displayed on 'New Theme' button"
                                />
                            </Link>
                        </div>
                    </div>
                </Row>
                <ThemeList history={this.props.history} />
            </Container>
        );
    }
}

Home.propTypes = {
    history: PropTypes.object
};
