import React from 'react';
import PropTypes from 'prop-types';
import styles from './Panel.module.css';
import { Container, Card, CardHeader, CardBody } from 'reactstrap';

export default class Panel extends React.Component {
    render() {
        return (
            <Card className={styles.card}>
                <CardHeader className={styles.cardHeader}>
                    {this.props.header}
                </CardHeader>
                <CardBody className={styles.cardBody}>
                    <Container>{this.props.children}</Container>
                </CardBody>
            </Card>
        );
    }
}

Panel.propTypes = {
    header: PropTypes.string,
    children: PropTypes.node
};
