import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import StepNumber from '../Enums/StepNumber';
import styles from './StepBar.module.css';

export default class StepBar extends React.Component {
    getStepOneClass() {
        if (this.props.activeStep === StepNumber.one) {
            return styles.activeStepOne;
        }
        return styles.inactiveStepOne;
    }

    getStepTwoClass() {
        if (this.props.activeStep === StepNumber.two) {
            return styles.activeStepTwo;
        }
        return styles.inactiveStepTwo;
    }

    render() {
        return (
            <div className={styles.bottom}>
                <Label className={this.getStepOneClass()}>
                    <FormattedMessage
                        id="StepBar.Step1"
                        defaultMessage="STEP 1"
                        description="Step 1 description"
                    />
                </Label>
                <Label className={this.getStepTwoClass()}>
                    <FormattedMessage
                        id="StepBar.Step2"
                        defaultMessage="STEP 2"
                        description="Step 2 description"
                    />
                </Label>
            </div>
        );
    }
}

StepBar.propTypes = {
    activeStep: PropTypes.number
};
