import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import PropTypes from 'prop-types';
import moreIcon from '../Icons/more.svg';
import styles from './EllipsesDropdown.module.scss';

class EllipsesDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle(e) {
        e.preventDefault();
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const { intl } = this.props;
        return (
            <Dropdown
                className={this.props.className}
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
            >
                <DropdownToggle color="light" className={styles.dropdownToggle}>
                    <img
                        className={styles.moreInfoIcon}
                        src={moreIcon}
                        alt={intl.formatMessage(definedTranslations.more)}
                    />
                </DropdownToggle>
                <DropdownMenu className={styles.dropdownMenu} right>
                    <DropdownItem
                        name="Edit"
                        onClick={this.props.onDropdownItemSelected}
                    >
                        <FormattedMessage
                            id="Common.Edit"
                            description="Standard Edit text"
                            defaultMessage="Edit"
                        />
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                        name="EditPermissions"
                        onClick={this.props.onDropdownItemSelected}
                    >
                        <FormattedMessage
                            id="EllipsesDropdown.EditPermissions"
                            description='Text for an "Edit Permissions" option'
                            defaultMessage="Edit Permissions"
                        />
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                        name="Delete"
                        onClick={this.props.onDropdownItemSelected}
                    >
                        <FormattedMessage
                            id="Common.Delete"
                            description="Standard Delete Text"
                            defaultMessage="Delete"
                        />
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

EllipsesDropdown.propTypes = {
    onDropdownItemSelected: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default injectIntl(EllipsesDropdown);
