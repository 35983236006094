export default class ProviderPermission {
    constructor(providerPermission) {
        this.providerId = providerPermission.providerId
            ? providerPermission.providerId
            : null;
        this.providerName = providerPermission.providerName
            ? providerPermission.providerName
            : '';
        this.hasPermission = providerPermission.hasPermission
            ? providerPermission.hasPermission
            : false;
    }
}
