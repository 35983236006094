import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './FontPicker.module.css';
import { fontChoices } from './../Constants.js';

function FontPicker(props) {
    return (
        <Fragment>
            <div className={styles.label}>{props.label}</div>
            <select
                className={styles.select}
                name={props.name}
                onChange={props.onValueChanged}
                value={props.selectedValue}
            >
                {fontChoices.map(fontName => {
                    return (
                        <option
                            className={styles.option}
                            name={fontName}
                            key={fontName}
                        >
                            {fontName}
                        </option>
                    );
                })}
            </select>
        </Fragment>
    );
}

FontPicker.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onValueChanged: PropTypes.func,
    selectedValue: PropTypes.string
};

export default FontPicker;
