import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './CornerPicker.module.css';
import cornerType from '../Enums/CornerType';

export default class CornerPicker extends React.Component {
    onCornerTypeSelected(event) {
        event.target.name = this.props.name;
        this.props.onValueChanged(event);
    }

    render() {
        return (
            <Fragment>
                <div className={styles.label}>{this.props.label}</div>
                <ButtonGroup className={styles.buttonGroup}>
                    <Button
                        className={styles.button}
                        value={cornerType.rectangular}
                        active={
                            this.props.selectedValue === cornerType.rectangular
                        }
                        onClick={event => this.onCornerTypeSelected(event)}
                    >
                        <FormattedMessage
                            id="Common.Rectangular"
                            defaultMessage="Rectangular"
                            description="Text to be displayed on 'Rectangular' selector"
                        />
                    </Button>
                    <Button
                        className={styles.button}
                        value={cornerType.rounded}
                        active={this.props.selectedValue === cornerType.rounded}
                        onClick={event => this.onCornerTypeSelected(event)}
                    >
                        <FormattedMessage
                            id="Common.Rounded"
                            defaultMessage="Rounded"
                            description="Text to be displayed on 'Rounded' selector"
                        />
                    </Button>
                    <Button
                        className={styles.button}
                        value={cornerType.capsule}
                        active={this.props.selectedValue === cornerType.capsule}
                        onClick={event => this.onCornerTypeSelected(event)}
                    >
                        <FormattedMessage
                            id="Common.Capsule"
                            defaultMessage="Capsule"
                            description="Text to be displayed on 'Capsule' selector"
                        />
                    </Button>
                </ButtonGroup>
            </Fragment>
        );
    }
}

CornerPicker.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    selectedValue: PropTypes.number,
    onValueChanged: PropTypes.func.isRequired
};
