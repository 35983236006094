import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    getWidgetThemeForReseller,
    getProviderPermissions,
    updateWidgetTheme,
    updateProviderPermissions,
    removeLogo
} from '../WidgetThemeService';
import { ThemeInformation } from './ThemeInformation';

export class EditPage extends Component {
    constructor(props) {
        super(props);
        this.onGetTheme = this.onGetTheme.bind(this);
        this.onGetProviderPermissions = this.onGetProviderPermissions.bind(
            this
        );
        this.updateTheme = this.updateTheme.bind(this);
    }
    async onGetTheme() {
        return await getWidgetThemeForReseller(
            this.props.resellerId,
            this.props.widgetThemeId
        );
    }

    async onGetProviderPermissions() {
        return await getProviderPermissions(
            this.props.resellerId,
            this.props.widgetThemeId
        );
    }

    async updateTheme(
        theme,
        themePermissions,
        resDiaryLogoUrl,
        customLogosToDelete
    ) {
        const providersWithPermission = [];
        for (const groupPermission of themePermissions) {
            for (const provider of groupPermission.providerPermissions) {
                if (provider.hasPermission) {
                    providersWithPermission.push(provider);
                }
            }
        }

        var resellerId = this.props.resellerId,
            widgetThemeId = this.props.widgetThemeId;
        var output = await theme.getThemeAsCssString(resDiaryLogoUrl);
        theme.cssString = output.css;
        await updateWidgetTheme(resellerId, widgetThemeId, theme);
        await updateProviderPermissions(
            resellerId,
            widgetThemeId,
            providersWithPermission
        );
        for (var i = 0; i < customLogosToDelete.length; i++) {
            await removeLogo(resellerId, customLogosToDelete[i]);
        }
        this.props.history.push(`/?resellerId=${resellerId}`);
    }

    render() {
        return (
            <ThemeInformation
                isEditing={true}
                onGetTheme={this.onGetTheme}
                onGetProviderPermissions={this.onGetProviderPermissions}
                updateTheme={this.updateTheme}
                resellerId={this.props.resellerId}
            />
        );
    }
}

EditPage.propTypes = {
    resellerId: PropTypes.number,
    widgetThemeId: PropTypes.number,
    history: PropTypes.object
};
