import { defineMessages } from 'react-intl';

const translations = defineMessages({
    background: {
        id: 'Common.Background',
        defaultMessage: 'Background',
        description: 'Label for "Background" selector'
    },
    primary: {
        id: 'ColourPanel.Primary',
        defaultMessage: 'Primary',
        description: 'Label for "Primary" selector'
    },
    secondary: {
        id: 'ColourPanel.Secondary',
        defaultMessage: 'Secondary',
        description: 'Label for "Secondary" selector'
    },
    tertiary: {
        id: 'ColourPanel.Tertiary',
        defaultMessage: 'Tertiary',
        description: 'Label for "Tertiary" selector'
    },
    text: {
        id: 'Common.Text',
        defaultMessage: 'Text',
        description: 'Label for "Text" label'
    },
    cornerRadius: {
        id: 'Common.CornerRadius',
        defaultMessage: 'Corner Radius',
        description: 'Label for "Corner Radius" selector'
    },
    whiteLogo: {
        id: 'BrandingPicker.WhiteLogo',
        defaultMessage: 'White Logo',
        description: 'Alt text for "White Logo" image'
    },
    blackLogo: {
        id: 'BrandingPicker.BlackLogo',
        defaultMessage: 'Black Logo',
        description: 'Alt text for "Black Logo" image'
    },
    outerTitle: {
        id: 'CalendarPanel.OuterTitle',
        defaultMessage: 'Outer',
        description: 'Title for the Outer panel'
    },
    border: {
        id: 'Common.Border',
        defaultMessage: 'Border',
        description: 'Title for the Border selector'
    },
    days: {
        id: 'Common.Days',
        defaultMessage: 'Days',
        description: 'Title for the Days panel'
    },
    selectedDay: {
        id: 'CalendarPanel.SelectedDay',
        defaultMessage: 'Selected Day',
        description: 'Title for the Selected Day panel'
    },
    formErrors: {
        id: 'DropdownsPanel.FormErrors',
        defaultMessage: 'Form Errors',
        description: 'Label for "Form Errors" selector'
    },
    placeholderText: {
        id: 'DropdownsPanel.PlaceholderText',
        defaultMessage: 'Placeholder Text',
        description: 'Label for "Placeholder Text" selector'
    },
    borderFocus: {
        id: 'DropdownsPanel.BorderFocus',
        defaultMessage: 'Border Focus',
        description: 'Label for "Border Focus" selector'
    },
    highlight: {
        id: 'DropdownsPanel.Highlight',
        defaultMessage: 'Highlight',
        description: 'Label for "Highlight" selector'
    },
    highlightText: {
        id: 'DropdownsPanel.HightlightText',
        defaultMessage: 'Highlight Text',
        description: 'Label for "Highlight Text" selector'
    },
    more: {
        id: 'Common.More',
        defaultMessage: 'More',
        description: 'Alt for "More" image'
    },
    editIcon: {
        id: 'Common.EditIcon',
        defaultMessage: 'Edit Icon',
        description: 'Alt for "Edit Icon" image'
    },
    searchIcon: {
        id: 'Common.SearchIcon',
        defaultMessage: 'Search Icon',
        description: 'Alt for "Search Icon" image'
    },
    logo: {
        id: 'Common.Logo',
        defaultMessage: 'Logo',
        description: 'Label for "logo" selector'
    },
    logoUploadWarning: {
        id: 'Common.LogoUploadWarning',
        defaultMessage:
            'Images have a maximum file size of 2.5MB and will be re-sized to 200x50 pixels.',
        description: 'Info displayed while uploading a logo'
    },
    resdiaryBranding: {
        id: 'Common.ResdiaryBranding',
        defaultMessage: 'ResDiary Branding',
        description: 'Label for "ResDiary Branding" selector'
    },
    diaryPermissionLabel: {
        id: 'PermissionsPanel.DiaryPermissionLabel',
        defaultMessage: 'Diaries with permission to use this theme',
        description: 'Label for "Diary permissions" list'
    },
    search: {
        id: 'Common.Search',
        defaultMessage: 'Search',
        description: 'Placeholder for "search" input'
    },
    boxTitle: {
        id: 'PromotionsPanel.BoxTitle',
        defaultMessage: 'Box',
        description: 'Title for "Box" panel'
    },
    optionTitle: {
        id: 'PromotionsPanel.OptionTitle',
        defaultMessage: 'Option',
        description: 'Title for "Option" panel'
    },
    optionHoverTitle: {
        id: 'PromotionsPanel.OptionHoverTitle',
        defaultMessage: 'Option Hover',
        description: 'Title for "Option Hover" panel'
    },
    selected: {
        id: 'PromotionsPanel.Selected',
        defaultMessage: 'Selected',
        description: 'Title for "Selected" panel'
    },
    font: {
        id: 'Common.Font',
        defaultMessage: 'Font',
        description: 'Label for "Font" selector'
    },
    themeName: {
        id: 'Common.ThemeName',
        defaultMessage: 'Theme Name',
        description: 'Placeholder for "Theme Name" input field'
    },
    customLogo: {
        id: 'Common.CustomLogo',
        defaultMessage: 'Custom Logo',
        description: 'Alt for "Custom Logo" image'
    },
    LeaveTimeMessage: {
        id: 'Common.LeaveTimeMessage',
        defaultMessage: 'Leave Time Message',
        description: 'Label for "Leave time message" selector'
    },
    cities: {
        id: 'Common.Cities',
        defaultMessage: 'Cities',
        description: 'Title for "Venue Selection" panel'
    },
    venues: {
        id: 'Common.Venues',
        defaultMessage: 'Venues',
        description: 'Title for "Venue Selection" panel'
    },
    venueSelection: {
        id: 'Common.VenueSelection',
        defaultMessage: 'VENUE SELECTION (GROUP WIDGET)',
        description: 'Label for "Venue Selection" panel'
    }
});

export default translations;
