import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import styles from './NamePanel.module.css';
import EditIcon from '../Icons/edit.svg';

class NamePanel extends React.Component {
    getName() {
        return this.props.theme.name ? this.props.theme.name : '';
    }

    render() {
        const { intl } = this.props;
        return (
            <FormGroup>
                <div className={styles.name}>
                    <Input
                        className={styles.nameInput}
                        value={this.getName()}
                        placeholder={intl.formatMessage(
                            definedTranslations.themeName
                        )}
                        type="text"
                        name="name"
                        id="name"
                        onChange={event => this.props.onValueChanged(event)}
                        readOnly={this.props.isNameReadOnly}
                    />
                    {this.props.isNameReadOnly && (
                        <img
                            className={styles.editIcon}
                            onClick={this.props.onEditIconClicked}
                            src={EditIcon}
                            alt={intl.formatMessage(
                                definedTranslations.editIcon
                            )}
                        />
                    )}
                </div>
            </FormGroup>
        );
    }
}

NamePanel.propTypes = {
    theme: PropTypes.object.isRequired,
    isNameReadOnly: PropTypes.bool,
    onValueChanged: PropTypes.func.isRequired,
    onEditIconClicked: PropTypes.func
};

export default injectIntl(NamePanel);
