import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import styles from './PanelRow.module.css';

export default class PanelRow extends React.Component {
    render() {
        return <Row className={styles.panelRow}>{this.props.children}</Row>;
    }
}

PanelRow.propTypes = {
    children: PropTypes.node
};
