import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';
import styles from './ThemeEntry.module.scss';
import EllipsesDropdown from './EllipsesDropdown.js';

class ThemeEntry extends React.Component {
    constructor(props) {
        super(props);
        this.onDropdownItemSelected = this.onDropdownItemSelected.bind(this);
    }

    onDropdownItemSelected(event) {
        this.props.onDropdownItemSelected(
            event,
            this.props.id,
            this.props.name
        );
    }

    render() {
        const { intl } = this.props;
        return (
            <Card>
                <CardHeader className={styles.themeCardHeader}>
                    <div>{this.props.name}</div>
                    <EllipsesDropdown
                        className={styles.themeCardHeaderButton}
                        onDropdownItemSelected={this.onDropdownItemSelected}
                    />
                </CardHeader>
                <CardBody className={styles.themeCardBody}>
                    <div
                        style={{
                            background: this.props.calendarDayBackgroundColour
                        }}
                    ></div>
                    <div className={styles.themeCardBodyRightPanel}>
                        <div
                            style={{
                                background: this.props
                                    .calendarSelectedBackgroundColour
                            }}
                        ></div>
                        <div
                            style={{
                                background: this.props
                                    .overallGeneralBackgroundColour
                            }}
                        >
                            {this.props.customLogoUrl && (
                                <img
                                    className={styles.logo}
                                    src={this.props.customLogoUrl}
                                    alt={intl.formatMessage(
                                        definedTranslations.logo
                                    )}
                                />
                            )}
                        </div>
                        <div
                            style={{
                                background: this.props
                                    .overallGeneralTextColour
                            }}
                        ></div>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

ThemeEntry.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    overallGeneralBackgroundColour: PropTypes.string,
    calendarDayBackgroundColour: PropTypes.string,
    calendarSelectedBackgroundColour: PropTypes.string,
    dropdownGeneralBackgroundColour: PropTypes.string,
    customLogoUrl: PropTypes.string,
    onDropdownItemSelected: PropTypes.func
};

export default injectIntl(ThemeEntry);
