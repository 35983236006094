import ProviderPermission from './ProviderPermission.js';

export default class GroupPermission {
    constructor(groupPermission) {
        this.groupId = groupPermission.groupId ? groupPermission.groupId : null;
        this.groupName = groupPermission.groupName
            ? groupPermission.groupName
            : '';
        this.providerPermissions = groupPermission.providerPermissions
            ? this.mapProviderPermissions(groupPermission.providerPermissions)
            : [];
    }

    mapProviderPermissions(providerPermissions) {
        return providerPermissions.map(
            providerPermission => new ProviderPermission(providerPermission)
        );
    }
}
