import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import isColour from 'is-color';
import styles from './ColourPicker.module.css';

class ColourPicker extends React.Component {
    constructor(props) {
        super(props);
        this.handlePickerChangeComplete = this.handlePickerChangeComplete.bind(
            this
        );
        this.validateColour = this.validateColour.bind(this);
        this.getColourValue = this.getColourValue.bind(this);
        this.state = {
            displayColourPicker: false
        };
    }

    handleWellClick() {
        this.setState({ displayColourPicker: !this.state.displayColourPicker });
    }

    handlePickerClose() {
        this.setState({ displayColourPicker: false });
    }

    handlePickerChangeComplete(colour, event, isInputChange) {
        event.target.name = this.props.name;
        if (isInputChange) {
            event.target.value = colour;
        } else {
            // Store non-transparent colours as hex
            event.target.value =
                colour.rgb.a === 1
                    ? colour.hex
                    : `rgba(${colour.rgb.r},${colour.rgb.g},${colour.rgb.b},${colour.rgb.a})`;
        }
        this.props.onValueChanged(event);
    }

    validateColour(colour, event) {
        event.target.name = this.props.name;
        event.target.value = isColour(colour) ? colour : '#FFFFFF';
        this.props.onValueChanged(event);
    }

    getColourValue() {
        return this.props.selectedValue ? this.props.selectedValue : '#FFFFFF';
    }

    render() {
        return (
            <div className={styles.outerDiv}>
                <span className={styles.label}>{this.props.label}</span>
                <div className={styles.innerDiv}>
                    <div
                        className={styles.colourWell}
                        style={{ backgroundColor: this.getColourValue() }}
                        onClick={() => this.handleWellClick()}
                    ></div>
                    <input
                        name={this.props.name}
                        className={styles.colourInput}
                        value={this.getColourValue()}
                        onBlur={event =>
                            this.validateColour(event.target.value, event)
                        }
                        onChange={event =>
                            this.handlePickerChangeComplete(
                                event.target.value,
                                event,
                                true
                            )
                        }
                    />
                </div>
                {this.state.displayColourPicker && (
                    <div className={styles.popover}>
                        <div
                            className={styles.cover}
                            onClick={event => this.handlePickerClose(event)}
                        />
                        <ChromePicker
                            color={this.props.selectedValue}
                            onChange={(color, event) =>
                                this.handlePickerChangeComplete(
                                    color,
                                    event,
                                    false
                                )
                            }
                        />
                    </div>
                )}
            </div>
        );
    }
}

ColourPicker.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    selectedValue: PropTypes.string,
    onValueChanged: PropTypes.func.isRequired
};

export default ColourPicker;
