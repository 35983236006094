import React, { Component, Fragment } from 'react';
import {
    getWidgetThemesForReseller,
    getWidgetThemesForProvider,
    deleteWidgetTheme
} from '../WidgetThemeService';
import ThemeListing from '../DomainObjects/ThemeListing.js';
import ThemeEntry from './ThemeEntry.js';
import DeleteThemeModal from './DeleteThemeModal.js';
import DeleteThemeErrorModal from './DeleteThemeErrorModal.js';
import queryStringHelper from '../Helpers/QueryStringHelper';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import StepNumber from '../Enums/StepNumber';
import styles from './ThemeList.module.css';

export class ThemeList extends Component {
    constructor(props) {
        super(props);
        this.onDropdownItemSelected = this.onDropdownItemSelected.bind(this);
        this.toggleDeleteThemeModal = this.toggleDeleteThemeModal.bind(this);
        this.toggleDeleteThemeErrorModal = this.toggleDeleteThemeErrorModal.bind(
            this
        );
        this.deleteThemeConfirmed = this.deleteThemeConfirmed.bind(this);
        this.state = {
            themes: [],
            resellerId: null,
            providerId: null,
            showDeleteThemeModal: false,
            showDeleteThemeErrorModal: false,
            themeIdToDelete: null,
            themeNameToDelete: ''
        };
    }

    componentDidMount() {
        this.loadThemes();
    }

    async loadThemes() {
        const resellerId = queryStringHelper.getParameterByName('resellerId');
        const providerId = queryStringHelper.getParameterByName('providerId');

        if (resellerId) {
            const json = await getWidgetThemesForReseller(resellerId);
            const themes = json.map(theme => new ThemeListing(theme));
            this.setState({ resellerId: resellerId, themes: themes });
        } else if (providerId) {
            const json = await getWidgetThemesForProvider(providerId);
            const themes = json.map(theme => new ThemeListing(theme));
            this.setState({ providerId: providerId, themes: themes });
        }
    }

    onDropdownItemSelected(event, id, name) {
        if (event.currentTarget.name === 'Edit') {
            this.props.history.push(
                `/${this.state.resellerId}/EditSimpleTheme/${id}?activeStep=${StepNumber.one}`
            );
        }
        if (event.currentTarget.name === 'EditPermissions') {
            this.props.history.push(
                `/${this.state.resellerId}/EditSimpleTheme/${id}?activeStep=${StepNumber.two}`
            );
        }
        if (event.currentTarget.name === 'Delete') {
            this.setState({
                themeIdToDelete: id,
                themeNameToDelete: name,
                showDeleteThemeModal: true
            });
        }
    }

    toggleDeleteThemeModal() {
        this.setState(prevState => ({
            showDeleteThemeModal: !prevState.showDeleteThemeModal
        }));
    }

    async deleteThemeConfirmed() {
        try {
            await deleteWidgetTheme(
                this.state.resellerId,
                this.state.themeIdToDelete
            );
            this.toggleDeleteThemeModal();
            this.loadThemes();
        } catch (error) {
            this.toggleDeleteThemeModal();
            this.toggleDeleteThemeErrorModal();
        }
    }

    toggleDeleteThemeErrorModal() {
        this.setState(prevState => ({
            showDeleteThemeErrorModal: !prevState.showDeleteThemeErrorModal
        }));
    }

    render() {
        return (
            <Fragment>
                <Row>
                    {this.state.themes.map(theme => {
                        return (
                            <Col
                                lg="3"
                                sm="6"
                                className={styles.col}
                                key={theme.id}
                            >
                                <ThemeEntry
                                    onDropdownItemSelected={
                                        this.onDropdownItemSelected
                                    }
                                    id={theme.id}
                                    name={theme.name}
                                    overallGeneralBackgroundColour={
                                        theme.overallGeneralBackgroundColour
                                    }
                                    calendarDayBackgroundColour={
                                        theme.calendarDayBackgroundColour
                                    }
                                    calendarSelectedBackgroundColour={
                                        theme.calendarSelectedBackgroundColour
                                    }
                                    overallGeneralTextColour={
                                        theme.overallGeneralTextColour
                                    }
                                    customLogoUrl={theme.customLogoUrl}
                                />
                            </Col>
                        );
                    })}
                </Row>
                {this.state.showDeleteThemeModal && (
                    <DeleteThemeModal
                        themeIdToDelete={this.state.themeIdToDelete}
                        themeNameToDelete={this.state.themeNameToDelete}
                        toggleDeleteThemeModal={this.toggleDeleteThemeModal}
                        deleteThemeConfirmed={this.deleteThemeConfirmed}
                    />
                )}
                {this.state.showDeleteThemeErrorModal && (
                    <DeleteThemeErrorModal
                        themeName={this.state.themeNameToDelete}
                        toggleDeleteThemeErrorModal={
                            this.toggleDeleteThemeErrorModal
                        }
                    />
                )}
            </Fragment>
        );
    }
}

ThemeList.propTypes = {
    history: PropTypes.object
};
