import React from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

export default class ProviderPermissionPicker extends React.Component {
    render() {
        return (
            <Label check>
                <Input
                    type="checkbox"
                    id={this.props.providerThemePermission.providerId}
                    checked={this.props.providerThemePermission.hasPermission}
                    onChange={this.props.onValueChanged}
                />
                {this.props.providerThemePermission.providerName}
            </Label>
        );
    }
}

ProviderPermissionPicker.propTypes = {
    onValueChanged: PropTypes.func.isRequired,
    providerThemePermission: PropTypes.object.isRequired
};
