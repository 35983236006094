import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import UploadLogoModal from './UploadLogoModal.js';
import styles from './LogoPicker.module.css';

class LogoPicker extends React.Component {
    constructor(props) {
        super(props);
        this.toggleUploadLogoModal = this.toggleUploadLogoModal.bind(this);
        this.state = {
            showLogoUploadModal: false
        };
    }

    toggleUploadLogoModal() {
        this.setState(prevState => ({
            showLogoUploadModal: !prevState.showLogoUploadModal
        }));
    }

    render() {
        const { intl } = this.props;
        return (
            <Fragment>
                <div className={styles.label}>{this.props.label}</div>
                {this.state.showLogoUploadModal && (
                    <UploadLogoModal
                        onFileValueChanged={this.props.onFileValueChanged}
                        toggleUploadLogoModal={this.toggleUploadLogoModal}
                        infoText={this.props.infoText}
                    />
                )}
                <div className={styles.buttons}>
                    {this.props.uploadedLogo ? (
                        <div className="d-flex">
                            <img
                                className={styles.customLogo}
                                src={this.props.uploadedLogo}
                                alt={intl.formatMessage(
                                    definedTranslations.customLogo
                                )}
                            />
                            <Button
                                close
                                onClick={this.props.removeUploadedLogo}
                            />
                        </div>
                    ) : (
                        <Button
                            className={styles.button}
                            outline
                            color="primary"
                            onClick={this.toggleUploadLogoModal}
                        >
                            <FormattedMessage
                                id="Common.Upload"
                                defaultMessage="Upload"
                                description="Standard Upload text"
                            />
                        </Button>
                    )}
                    {this.props.showLibraryOption && (
                        <Button outline color="primary">
                            <FormattedMessage
                                id="LogoPicker.ChooseFromLibrary"
                                defaultMessage="Choose from library"
                                description="Text for the 'Choose from library' button"
                            />
                        </Button>
                    )}
                </div>
                <div className={styles.infoText}>{this.props.infoText}</div>
            </Fragment>
        );
    }
}

LogoPicker.propTypes = {
    label: PropTypes.string,
    infoText: PropTypes.string,
    showLibraryOption: PropTypes.bool,
    onFileValueChanged: PropTypes.func.isRequired
};

export default injectIntl(LogoPicker);
