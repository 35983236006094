import React, { Fragment } from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './GroupPermissionPicker.module.css';
import ProviderPermissionPicker from './ProviderPermissionPicker.js';

export default class GroupPermissionPicker extends React.Component {
    areAllProvidersChecked() {
        for (const providerPermission of this.props.groupThemePermissions
            .providerPermissions) {
            if (!providerPermission.hasPermission) {
                return false;
            }
        }
        return true;
    }

    getFilteredProviders(providers) {
        return this.isFilterEnabled()
            ? providers.reduce((filtered, provider) => {
                  if (
                      provider.providerName
                          .toLowerCase()
                          .indexOf(this.props.searchTerm) > -1
                  ) {
                      filtered.push(provider);
                  }
                  return filtered;
              }, [])
            : providers;
    }

    isFilterEnabled() {
        return this.props.searchTerm !== '';
    }

    render() {
        const filteredResults = this.getFilteredProviders(
            this.props.groupThemePermissions.providerPermissions
        );
        const numberOfResults = filteredResults.length;

        return (
            <Fragment>
                <Label className={styles.label} hidden={numberOfResults === 0}>
                    <Input
                        type="checkbox"
                        id={this.props.groupThemePermissions.groupId}
                        onChange={this.props.onGroupPermissionChanged}
                        checked={this.areAllProvidersChecked()}
                        hidden={this.isFilterEnabled()}
                    />
                    {this.props.groupThemePermissions.groupName}
                </Label>
                {filteredResults.map(themePermission => {
                    return (
                        <div
                            className={styles.providers}
                            key={themePermission.providerId}
                        >
                            <ProviderPermissionPicker
                                providerThemePermission={themePermission}
                                onValueChanged={
                                    this.props.onProviderPermissionChanged
                                }
                                key={themePermission.providerId}
                            />
                        </div>
                    );
                })}
            </Fragment>
        );
    }
}

GroupPermissionPicker.propTypes = {
    onGroupPermissionChanged: PropTypes.func.isRequired,
    onProviderPermissionChanged: PropTypes.func.isRequired,
    groupThemePermissions: PropTypes.object.isRequired,
    searchTerm: PropTypes.string
};
