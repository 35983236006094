import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './RadiusPicker.module.css';

export default class RadiusPicker extends React.Component {
    handleSliderChange(event) {
        this.setState({ selectedValue: parseInt(event.target.value, 10) });
        event.target.name = this.props.name;
        this.props.onNumberValueChanged(event);
    }

    getMinValue() {
        return this.props.minValue ? this.props.minValue : 0;
    }

    getStepValue() {
        return this.props.step ? this.props.step : 1;
    }

    render() {
        return (
            <Fragment>
                <span className={styles.label}>{this.props.label}</span>
                <div>
                    <input
                        className={styles.slider}
                        type="range"
                        min={this.getMinValue()}
                        max={this.props.maxValue}
                        step={this.getStepValue()}
                        value={this.props.selectedValue}
                        onChange={event => this.handleSliderChange(event)}
                    />
                </div>
            </Fragment>
        );
    }
}

RadiusPicker.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    minValue: PropTypes.number,
    maxValue: PropTypes.number.isRequired,
    step: PropTypes.number,
    onNumberValueChanged: PropTypes.func.isRequired,
    selectedValue: PropTypes.number
};
