import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    getGroupsAndProvidersForReseller,
    createWidgetThemeForReseller,
    updateProviderPermissions,
    removeLogo
} from '../WidgetThemeService';
import { ThemeInformation } from './ThemeInformation';

export class CreatePage extends Component {
    constructor(props) {
        super(props);
        this.onGetGroupsAndProviders = this.onGetGroupsAndProviders.bind(this);
        this.createNewTheme = this.createNewTheme.bind(this);
    }

    async onGetGroupsAndProviders() {
        return await getGroupsAndProvidersForReseller(this.props.resellerId);
    }

    async createNewTheme(
        theme,
        themePermissions,
        resDiaryLogoUrl,
        customLogosToDelete
    ) {
        const providersWithPermission = [];
        for (const groupPermission of themePermissions) {
            for (const provider of groupPermission.providerPermissions) {
                if (provider.hasPermission) {
                    providersWithPermission.push(provider);
                }
            }
        }

        var resellerId = this.props.resellerId;
        var output = await theme.getThemeAsCssString(resDiaryLogoUrl);
        theme.cssString = output.css;
        var widgetThemeId = await createWidgetThemeForReseller(
            resellerId,
            theme
        );
        await updateProviderPermissions(
            resellerId,
            widgetThemeId,
            providersWithPermission
        );
        for (var i = 0; i < customLogosToDelete.length; i++) {
            await removeLogo(resellerId, customLogosToDelete[i]);
        }
        this.props.history.push(`/?resellerId=${resellerId}`);
    }

    render() {
        return (
            <ThemeInformation
                isEditing={false}
                onGetGroupsAndProviders={this.onGetGroupsAndProviders}
                createNewTheme={this.createNewTheme}
                resellerId={this.props.resellerId}
            />
        );
    }
}

CreatePage.propTypes = {
    resellerId: PropTypes.number,
    history: PropTypes.object
};
