import React from 'react';
import ReactDOM from 'react-dom';
import './Styles/style.scss';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import { IntlProvider } from 'react-intl';

import messages_fr from './Translations/fr.json';
import messages_en from './Translations/en.json';

const messages = {
    fr: messages_fr,
    en: messages_en
};

const language = navigator.language.split(/[-_]/)[0]; // language without region code
const rootElement = document.getElementById('root');

ReactDOM.render(
    <IntlProvider locale={language} messages={messages[language]}>
        <App />
    </IntlProvider>,
    rootElement
);

registerServiceWorker();
