import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import PermissionsPicker from './PermissionsPicker';
import Panel from './Panel.js';
import { Col } from 'reactstrap';
import PanelRow from './PanelRow.js';

function PermissionsPanel(props) {
    const { intl } = props;
    return (
        <Panel header="PERMISSIONS">
            <PanelRow>
                <Col sm="12">
                    <PermissionsPicker
                        label={intl.formatMessage(
                            definedTranslations.diaryPermissionLabel
                        )}
                        themePermissions={props.themePermissions}
                        onGroupPermissionChanged={
                            props.onGroupPermissionChanged
                        }
                        onProviderPermissionChanged={
                            props.onProviderPermissionChanged
                        }
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

PermissionsPanel.propTypes = {
    themePermissions: PropTypes.array.isRequired,
    onGroupPermissionChanged: PropTypes.func.isRequired,
    onProviderPermissionChanged: PropTypes.func.isRequired
};

export default injectIntl(PermissionsPanel);
