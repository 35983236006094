import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import ColourPicker from './ColourPicker';
import Panel from './Panel.js';
import { Col } from 'reactstrap';
import PanelRow from './PanelRow.js';
import RadiusPicker from './RadiusPicker.js';

function AdvancedGeneralPanel(props) {
    const { intl } = props;
    return (
        <Panel header="GENERAL">
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="overallGeneralBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={
                            props.theme.overallGeneralBackgroundColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="overallGeneralTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.overallGeneralTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="overallGeneralTableReturnColour"
                        label={intl.formatMessage(
                            definedTranslations.LeaveTimeMessage
                        )}
                        selectedValue={
                            props.theme.overallGeneralTableReturnColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow className="subsequentRow">
                <Col sm="4">
                    <RadiusPicker
                        name="generalBorderRadius"
                        label={intl.formatMessage(
                            definedTranslations.cornerRadius
                        )}
                        maxValue={20}
                        selectedValue={props.theme.overallGeneralBorderRadius}
                        onNumberValueChanged={props.onNumberValueChanged}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

AdvancedGeneralPanel.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onNumberValueChanged: PropTypes.func.isRequired
};

export default injectIntl(AdvancedGeneralPanel);
