import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import FontPicker from './FontPicker.js';
import LogoPicker from './LogoPicker.js';
import BrandingPicker from './BrandingPicker.js';
import Panel from './Panel.js';
import PanelRow from './PanelRow.js';

function OtherPanel(props) {
    const { intl } = props;
    return (
        <Panel header="OTHER">
            <PanelRow>
                <Col>
                    <FontPicker
                        name="overallGeneralFont"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.overallGeneralFont}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col>
                    <LogoPicker
                        showLibraryOption={false}
                        onFileValueChanged={props.onFileValueChanged}
                        label={intl.formatMessage(definedTranslations.logo)}
                        infoText={intl.formatMessage(
                            definedTranslations.logoUploadWarning
                        )}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col>
                    <BrandingPicker
                        name="resdiaryLogo"
                        resDiaryLogoUrl={props.resDiaryLogoUrl}
                        label={intl.formatMessage(
                            definedTranslations.resdiaryBranding
                        )}
                        selectedValue={props.theme.resdiaryLogo}
                        onValueChanged={props.onNumberValueChanged}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

OtherPanel.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onNumberValueChanged: PropTypes.func.isRequired,
    onFileValueChanged: PropTypes.func.isRequired
};

export default injectIntl(OtherPanel);
