import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import { Col } from 'reactstrap';
import Panel from './Panel.js';
import PanelRow from './PanelRow.js';
import PanelTitle from './PanelTitle.js';
import ColourPicker from './ColourPicker.js';

function VenueSelectionPanel(props) {
    const { intl } = props;
    return (
        <Panel header={intl.formatMessage(definedTranslations.venueSelection)}>
            <PanelRow>
                <Col>
                    <PanelTitle
                        title={intl.formatMessage(definedTranslations.cities)}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="citiesBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={props.theme.citiesBackgroundColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="citiesTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.citiesTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col>
                    <PanelTitle
                        title={intl.formatMessage(definedTranslations.venues)}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="venuesBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={props.theme.venuesBackgroundColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="venuesTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.venuesTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

VenueSelectionPanel.propTypes = {};

export default injectIntl(VenueSelectionPanel);
