import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import ColourPicker from './ColourPicker';
import RadiusPicker from './RadiusPicker.js';
import Panel from './Panel.js';
import PanelTitle from './PanelTitle.js';
import PanelRow from './PanelRow.js';

function PromotionsPanel(props) {
    const { intl } = props;
    return (
        <Panel header="PROMOTIONS">
            <PanelRow>
                <Col>
                    <PanelTitle
                        title={intl.formatMessage(definedTranslations.boxTitle)}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="promoBoxBorderColour"
                        label={intl.formatMessage(definedTranslations.border)}
                        selectedValue={props.theme.promoBoxBorderColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <RadiusPicker
                        name="promoBoxBorderRadius"
                        label={intl.formatMessage(
                            definedTranslations.cornerRadius
                        )}
                        maxValue={20}
                        selectedValue={props.theme.promoBoxBorderRadius}
                        onNumberValueChanged={props.onNumberValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col>
                    <PanelTitle
                        title={intl.formatMessage(
                            definedTranslations.optionTitle
                        )}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="promoOptionBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={props.theme.promoOptionBackgroundColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="promoOptionTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.promoOptionTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col>
                    <PanelTitle
                        title={intl.formatMessage(
                            definedTranslations.optionHoverTitle
                        )}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="promoOptionHoverBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={
                            props.theme.promoOptionHoverBackgroundColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="promoOptionHoverTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.promoOptionHoverTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col>
                    <PanelTitle
                        title={intl.formatMessage(definedTranslations.selected)}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="promoSelectedBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={
                            props.theme.promoSelectedBackgroundColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="promoSelectedTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.promoSelectedTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

PromotionsPanel.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onNumberValueChanged: PropTypes.func.isRequired
};
export default injectIntl(PromotionsPanel);
