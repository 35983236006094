export const fontChoices = [
    'Abril Fatface',
    'Arya',
    'Droid Sans',
    'Josefin Slab',
    'Lato',
    'Montserrat',
    'Old Standard TT',
    'Open Sans',
    'Oswald',
    'Oxygen',
    'PT Sans',
    'Raleway',
    'Ubuntu',
    'Vollkorn'
];
