import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Button } from 'reactstrap';
import AdvancedGeneralPanel from './AdvancedGeneralPanel.js';
import ButtonsPanel from './ButtonsPanel.js';
import BookingSummaryPanel from './BookingSummaryPanel.js';
import CalendarPanel from './CalendarPanel.js';
import PromotionsPanel from './PromotionsPanel.js';
import DropdownsPanel from './DropdownsPanel.js';
import OtherPanel from './OtherPanel.js';
import VenueSelectionPanel from './VenueSelectionPanel.js';
import styles from './AdvancedTheme.module.css';

function AdvancedTheme(props) {
    return (
        <Form>
            <AdvancedGeneralPanel
                theme={props.theme}
                onValueChanged={props.onValueChanged}
                onNumberValueChanged={props.onNumberValueChanged}
            />
            <ButtonsPanel
                theme={props.theme}
                onValueChanged={props.onValueChanged}
            />
            <BookingSummaryPanel
                theme={props.theme}
                onValueChanged={props.onValueChanged}
                onNumberValueChanged={props.onNumberValueChanged}
            />
            <CalendarPanel
                theme={props.theme}
                onValueChanged={props.onValueChanged}
                onNumberValueChanged={props.onNumberValueChanged}
            />
            <PromotionsPanel
                theme={props.theme}
                onValueChanged={props.onValueChanged}
                onNumberValueChanged={props.onNumberValueChanged}
            />
            <DropdownsPanel
                theme={props.theme}
                onValueChanged={props.onValueChanged}
                onNumberValueChanged={props.onNumberValueChanged}
            />
            <VenueSelectionPanel
                theme={props.theme}
                onValueChanged={props.onValueChanged}
            />
            <OtherPanel
                theme={props.theme}
                resDiaryLogoUrl={props.resDiaryLogoUrl}
                onValueChanged={props.onValueChanged}
                onNumberValueChanged={props.onNumberValueChanged}
                onFileValueChanged={props.onFileValueChanged}
            />
            <div className={styles.continueBtn}>
                <Button
                    color="primary"
                    onClick={() => props.onContinueButtonClicked()}
                >
                    <FormattedMessage
                        id="Common.Continue"
                        defaultMessage="Continue"
                        description="Text to be displayed on 'Continue' button"
                    />
                </Button>
            </div>
        </Form>
    );
}

AdvancedTheme.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onNumberValueChanged: PropTypes.func.isRequired,
    onFileValueChanged: PropTypes.func.isRequired,
    onContinueButtonClicked: PropTypes.func.isRequired
};

export default AdvancedTheme;
