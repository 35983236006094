import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import styles from './NotFound.module.css';

export default function NotFound() {
    return (
        <div className={styles.fullBackground}>
            <Container>
                <Row>
                    <Col>
                        <FormattedMessage
                            id="NotFound.Line1"
                            defaultMessage="Looking for a theme builder in all the wrong places?"
                            description="First line of 'page not found' page"
                            tagName="h1"
                        />
                        <FormattedMessage
                            id="NotFound.Line2"
                            defaultMessage="Try looking somewhere else"
                            description="Second line of 'page not found' page"
                            tagName="h3"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
