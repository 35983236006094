import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Input, FormGroup, Label } from 'reactstrap';
import styles from './BrandingPicker.module.css';
import brandingType from '../Enums/BrandingType';

class BrandingPicker extends React.Component {
    onBrandingSelected(event) {
        event.target.name = this.props.name;
        this.props.onValueChanged(event);
    }

    render() {
        const { intl } = this.props;
        return (
            <Fragment>
                <div className={styles.label}>{this.props.label}</div>
                <FormGroup className={styles.formGroup} check>
                    <Label check className={styles.radioButton}>
                        <Input
                            value={brandingType.white}
                            checked={
                                this.props.selectedValue === brandingType.white
                            }
                            onChange={event => this.onBrandingSelected(event)}
                            type="radio"
                            name="logoRadio"
                        />
                        <img
                            src={this.props.resDiaryLogoUrl}
                            className={styles.whiteLogo}
                            alt={intl.formatMessage(
                                definedTranslations.whiteLogo
                            )}
                        />
                    </Label>
                    <Label check className={styles.radioButton}>
                        <Input
                            value={brandingType.black}
                            checked={
                                this.props.selectedValue === brandingType.black
                            }
                            onChange={event => this.onBrandingSelected(event)}
                            type="radio"
                            name="logoRadio"
                        />
                        <img
                            src={this.props.resDiaryLogoUrl.replace(
                                `newwidgetlogo1.svg`,
                                `newwidgetlogo2.svg`
                            )}
                            className={styles.blackLogo}
                            alt={intl.formatMessage(
                                definedTranslations.blackLogo
                            )}
                        />
                    </Label>
                </FormGroup>
            </Fragment>
        );
    }
}

BrandingPicker.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    selectedValue: PropTypes.number
};

export default injectIntl(BrandingPicker);
