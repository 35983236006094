import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import NamePanel from './NamePanel.js';
import AdvancedTheme from './AdvancedTheme.js';
import styles from './CreatePage.module.css';
import { uploadLogo } from '../WidgetThemeService';
import Theme from '../DomainObjects/Theme.js';
import GroupPermission from '../DomainObjects/GroupPermission.js';
import QueryStringHelper from '../Helpers/QueryStringHelper';
import ThemePermissions from './ThemePermissions.js';
import StepNumber from '../Enums/StepNumber';
import StepBar from './StepBar.js';
import SimpleTheme from './SimpleTheme.js';
import { getContext } from '../ContextService.js';

export class ThemeInformation extends Component {
    constructor(props) {
        super(props);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onNumberValueChanged = this.onNumberValueChanged.bind(this);
        this.onFileValueChanged = this.onFileValueChanged.bind(this);
        this.removeUploadedLogo = this.removeUploadedLogo.bind(this);
        this.updateCornerRadii = this.updateCornerRadii.bind(this);
        this.updateBackgroundColours = this.updateBackgroundColours.bind(this);
        this.onProviderPermissionChanged = this.onProviderPermissionChanged.bind(
            this
        );
        this.onGroupPermissionChanged = this.onGroupPermissionChanged.bind(
            this
        );
        this.onContinueButtonClicked = this.onContinueButtonClicked.bind(this);
        this.onBackButtonClicked = this.onBackButtonClicked.bind(this);
        this.onNameEditIconClicked = this.onNameEditIconClicked.bind(this);
        this.updateWidgetPreview = this.updateWidgetPreview.bind(this);
        this.createNewTheme = this.createNewTheme.bind(this);
        this.updateTheme = this.updateTheme.bind(this);

        this.state = {
            theme: this.props.isEditing ? {} : new Theme(),
            widgetThemeId: null,
            isSimpleTheme: true,
            themePermissions: [],
            activeStep: StepNumber.one,
            customLogoFileName: '',
            isNameReadOnly: this.props.isEditing,
            // Store any logos which have been uploaded/replaced
            // but still exist in blob storage
            customLogosToDelete: []
        };
    }

    async componentDidMount() {
        const context = await getContext();
        this.setState({
            widgetPreviewUrl: context.widgetPreviewUrl,
            resDiaryLogoUrl: context.resDiaryLogoUrl
        });
        if (this.props.isEditing) {
            var themeJson = await this.props.onGetTheme();
            const originalTheme = new Theme(themeJson);
            this.setState({
                theme: originalTheme,
                customLogoFileName: this.getFilenameFromBlobUrl(
                    originalTheme.customLogoUrl
                )
            });
            this.iframe.onload = () => {
                this.updateWidgetPreview(originalTheme);
            };
            var permissionsJson = await this.props.onGetProviderPermissions();
            this.setState({
                themePermissions: permissionsJson.map(
                    groupJson => new GroupPermission(groupJson)
                )
            });
            this.getRequestedStep();
        } else {
            var groupsAndProvidersJson = await this.props.onGetGroupsAndProviders();
            this.setState({
                themePermissions: groupsAndProvidersJson.map(
                    groupJson => new GroupPermission(groupJson)
                )
            });
            this.iframe.onload = () => {
                this.updateWidgetPreview(this.state.theme);
            };
        }
    }

    async updateWidgetPreview(theme) {
        var output = await this.getThemeCss(theme);
        this.iframe.contentWindow.postMessage(
            { css: output.css },
            this.state.widgetPreviewUrl
        );
    }

    async getThemeCss(theme) {
        return await theme.getThemeAsCssString(this.state.resDiaryLogoUrl);
    }

    getRequestedStep() {
        const requestedStep = QueryStringHelper.getParameterByName(
            'activeStep'
        );
        if (requestedStep) {
            this.setState({ activeStep: parseInt(requestedStep, 10) });
        }
    }

    toggleMode() {
        this.setState({ isSimpleTheme: !this.state.isSimpleTheme });
    }

    getFilenameFromBlobUrl(blobUrl) {
        return blobUrl.replace(/^.*[\\/]/, '');
    }

    onValueChanged(event) {
        const theme = new Theme(this.state.theme);
        switch (event.target.name) {
            case 'backgroundColours':
                this.updateBackgroundColours(theme, event.target.value);
                break;
            case 'primaryColours':
                this.updatePrimaryColours(theme, event.target.value);
                break;
            case 'secondaryColours':
                this.updateSecondaryColours(theme, event.target.value);
                break;
            case 'tertiaryColours':
                this.updateTertiaryColours(theme, event.target.value);
                break;
            default:
                theme[[event.target.name]] = event.target.value;
                break;
        }
        this.setState({ theme });
        this.updateWidgetPreview(theme);
    }

    onNumberValueChanged(event) {
        const theme = new Theme(this.state.theme);
        switch (event.target.name) {
            case 'overallGeneralBorderRadius':
                this.updateCornerRadii(theme, parseInt(event.target.value, 10));
                break;
            case 'generalBorderRadius':
                this.updateGeneralCornerRadii(
                    theme,
                    parseInt(event.target.value, 10)
                );
                break;
            default:
                theme[[event.target.name]] = parseInt(event.target.value, 10);
                break;
        }
        this.setState({ theme });
        this.updateWidgetPreview(theme);
    }

    async onFileValueChanged(event) {
        var logoData = await uploadLogo(
            this.props.resellerId,
            event.target.files[0]
        );
        const theme = new Theme(this.state.theme);
        theme.customLogoUrl = logoData.uri;
        this.setState(
            {
                theme: theme,
                customLogoFileName: this.getFilenameFromBlobUrl(logoData.path)
            },
            () => this.updateWidgetPreview(theme)
        );
    }

    removeUploadedLogo() {
        const theme = new Theme(this.state.theme);
        theme.customLogoUrl = '';
        this.setState(
            prevState => {
                prevState.customLogosToDelete.push(
                    prevState.customLogoFileName
                );
                return {
                    theme: theme,
                    customLogoFileName: '',
                    customLogosToDelete: prevState.customLogosToDelete
                };
            },
            () => this.updateWidgetPreview(theme)
        );
    }

    onProviderPermissionChanged(event) {
        const changeEvent = event.target;
        this.setState(prevState => {
            prevState.themePermissions.forEach(groupPermission => {
                groupPermission.providerPermissions.forEach(
                    providerPermission => {
                        if (
                            providerPermission.providerId ===
                            parseInt(changeEvent.id, 10)
                        ) {
                            providerPermission.hasPermission =
                                changeEvent.checked;
                        }
                    }
                );
            });
            return { themePermissions: prevState.themePermissions };
        });
    }

    onGroupPermissionChanged(event) {
        const changeEvent = event.target;
        this.setState(prevState => {
            prevState.themePermissions.forEach(groupPermission => {
                if (groupPermission.groupId === parseInt(changeEvent.id, 10)) {
                    groupPermission.providerPermissions.forEach(
                        providerPermission => {
                            providerPermission.hasPermission =
                                changeEvent.checked;
                        }
                    );
                }
            });
            return { themePermissions: prevState.themePermissions };
        });
    }

    updateCornerRadii(theme, newRadius) {
        theme[['overallGeneralBorderRadius']] = newRadius;
        theme[['bookingSummaryBorderRadius']] = newRadius;
        theme[['calendarBorderRadius']] = newRadius;
        theme[['dropdownBorderRadius']] = newRadius;
        theme[['promoBoxBorderRadius']] = newRadius;
    }

    updateGeneralCornerRadii(theme, newRadius) {
        theme[['overallGeneralBorderRadius']] = newRadius;
    }

    updateBackgroundColours(theme, newColour) {
        theme[['overallGeneralBackgroundColour']] = newColour;
        theme[['bookingSummaryBackgroundColour']] = newColour;
        theme[['calendarBackgroundColour']] = newColour;
        theme[['promoOptionBackgroundColour']] = newColour;
        theme[['calendarBorderColour']] = newColour;
        theme[['promoBoxBorderColour']] = newColour;
    }

    updatePrimaryColours(theme, newColour) {
        theme[['calendarDayBackgroundColour']] = newColour;
        theme[['dropdownGeneralValidationErrorColour']] = newColour;
        theme[['dropdownBorderColour']] = newColour;
        theme[['dropdownBorderFocusColour']] = newColour;
        theme[['venuesBackgroundColour']] = newColour;
    }

    updateSecondaryColours(theme, newColour) {
        theme[['calendarSelectedBackgroundColour']] = newColour;
        theme[['overallGeneralTableReturnColour']] = newColour;
        theme[['overallBookButtonColour']] = newColour;
        theme[['promoOptionHoverBackgroundColour']] = newColour;
        theme[['promoSelectedBackgroundColour']] = newColour;
        theme[['citiesBackgroundColour']] = newColour;
    }

    updateTertiaryColours(theme, newColour) {
        theme[['overallGeneralTextColour']] = newColour;
        theme[['overallBookButtonFontColour']] = newColour;
        theme[['bookingSummaryTextColour']] = newColour;
        theme[['calendarMonthAndDateTextColour']] = newColour;
        theme[['calendarDayTextColour']] = newColour;
        theme[['calendarSelectedTextColour']] = newColour;
        theme[['promoOptionTextColour']] = newColour;
        theme[['promoOptionHoverTextColour']] = newColour;
        theme[['promoSelectedTextColour']] = newColour;
        theme[['citiesTextColour']] = newColour;
        theme[['venuesTextColour']] = newColour;
    }

    onContinueButtonClicked() {
        this.setState({ activeStep: StepNumber.two });
    }

    onBackButtonClicked() {
        this.setState({ activeStep: StepNumber.one });
    }

    onNameEditIconClicked() {
        this.setState({ isNameReadOnly: false });
    }

    async createNewTheme() {
        await this.props.createNewTheme(
            this.state.theme,
            this.state.themePermissions,
            this.state.resDiaryLogoUrl,
            this.state.customLogosToDelete
        );
    }

    async updateTheme() {
        await this.props.updateTheme(
            this.state.theme,
            this.state.themePermissions,
            this.state.resDiaryLogoUrl,
            this.state.customLogosToDelete
        );
    }

    render() {
        if (!this.state.widgetPreviewUrl) {
            return null;
        }
        var themeComponent;
        const props = {
            theme: this.state.theme,
            resDiaryLogoUrl: this.state.resDiaryLogoUrl,
            onValueChanged: this.onValueChanged,
            onNumberValueChanged: this.onNumberValueChanged,
            onFileValueChanged: this.onFileValueChanged,
            onContinueButtonClicked: this.onContinueButtonClicked,
            removeUploadedLogo: this.removeUploadedLogo
        };
        if (this.state.activeStep === StepNumber.one) {
            themeComponent = this.state.isSimpleTheme ? (
                <SimpleTheme {...props} />
            ) : (
                <AdvancedTheme {...props} />
            );
        } else {
            themeComponent = (
                <ThemePermissions
                    themePermissions={this.state.themePermissions}
                    onGroupPermissionChanged={this.onGroupPermissionChanged}
                    onProviderPermissionChanged={
                        this.onProviderPermissionChanged
                    }
                    onBackButtonClicked={this.onBackButtonClicked}
                    isEditing={this.props.isEditing}
                    createNewTheme={this.createNewTheme}
                    updateTheme={this.updateTheme}
                />
            );
        }

        return (
            <div className={styles.mainDiv}>
                <div className={styles.leftPanel}>
                    <div className={styles.namePanel}>
                        <NamePanel
                            isNameReadOnly={this.state.isNameReadOnly}
                            theme={this.state.theme}
                            onValueChanged={this.onValueChanged}
                            onEditIconClicked={this.onNameEditIconClicked}
                        />
                    </div>
                    <iframe
                        id="widgetpreview"
                        title="widget"
                        src={this.state.widgetPreviewUrl}
                        ref={frameElement => (this.iframe = frameElement)}
                    ></iframe>
                </div>
                <div className={styles.rightPanel}>
                    <StepBar activeStep={this.state.activeStep} />
                    {this.state.activeStep === StepNumber.one && (
                        <span
                            className={styles.toggleLink}
                            onClick={() => this.toggleMode()}
                        >
                            {this.state.isSimpleTheme ? (
                                <FormattedMessage
                                    id="ThemeInformation.Advanced"
                                    defaultMessage="Advanced"
                                    description="Standard Advanced text"
                                />
                            ) : (
                                <FormattedMessage
                                    id="Common.Back"
                                    defaultMessage="Back"
                                    description="Standard Back text"
                                />
                            )}
                        </span>
                    )}
                    {themeComponent}
                </div>
            </div>
        );
    }
}

ThemeInformation.propTypes = {
    resellerId: PropTypes.number,
    history: PropTypes.object,
    isEditing: PropTypes.bool
};
