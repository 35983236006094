import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import SearchIcon from '../Icons/search.svg';
import GroupPermissionPicker from './GroupPermissionPicker.js';
import styles from './PermissionsPicker.module.css';

class PermissionsPicker extends React.Component {
    constructor(props) {
        super(props);
        this.filterSearch = this.filterSearch.bind(this);
        this.state = { searchTerm: '' };
    }

    filterSearch(searchTerm) {
        this.setState({ searchTerm: searchTerm.toLowerCase() });
    }

    render() {
        const { intl } = this.props;
        return (
            <Fragment>
                <InputGroup className={styles.searchGroup} key={1}>
                    <InputGroupAddon
                        addonType="prepend"
                        className={styles.imagePrepend}
                    >
                        <img
                            className={styles.searchIcon}
                            src={SearchIcon}
                            alt={intl.formatMessage(
                                definedTranslations.searchIcon
                            )}
                        />
                    </InputGroupAddon>
                    <Input
                        autoFocus
                        type="text"
                        className={styles.searchBox}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                        placeholder={intl.formatMessage(
                            definedTranslations.search
                        )}
                        onChange={e => this.filterSearch(e.target.value)}
                    />
                </InputGroup>
                <Label className={styles.label}>{this.props.label}</Label>
                {this.props.themePermissions.map(themePermission => {
                    return (
                        <GroupPermissionPicker
                            groupThemePermissions={themePermission}
                            onGroupPermissionChanged={
                                this.props.onGroupPermissionChanged
                            }
                            onProviderPermissionChanged={
                                this.props.onProviderPermissionChanged
                            }
                            searchTerm={this.state.searchTerm}
                            key={themePermission.groupId}
                        />
                    );
                })}
            </Fragment>
        );
    }
}

PermissionsPicker.propTypes = {
    label: PropTypes.string,
    onGroupPermissionChanged: PropTypes.func.isRequired,
    onProviderPermissionChanged: PropTypes.func.isRequired,
    themePermissions: PropTypes.array.isRequired
};

export default injectIntl(PermissionsPicker);
