import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import ColourPicker from './ColourPicker';
import RadiusPicker from './RadiusPicker.js';
import Panel from './Panel.js';
import PanelRow from './PanelRow.js';

function DropdownsPanel(props) {
    const { intl } = props;
    return (
        <Panel header="DROPDOWNS">
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="dropdownGeneralBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.background
                        )}
                        selectedValue={
                            props.theme.dropdownGeneralBackgroundColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="dropdownGeneralTextColour"
                        label={intl.formatMessage(definedTranslations.text)}
                        selectedValue={props.theme.dropdownGeneralTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="dropdownGeneralValidationErrorColour"
                        label={intl.formatMessage(
                            definedTranslations.formErrors
                        )}
                        selectedValue={
                            props.theme.dropdownGeneralValidationErrorColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="dropdownGeneralPlaceholderColour"
                        label={intl.formatMessage(
                            definedTranslations.placeholderText
                        )}
                        selectedValue={
                            props.theme.dropdownGeneralPlaceholderColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="dropdownBorderColour"
                        label={intl.formatMessage(definedTranslations.border)}
                        selectedValue={props.theme.dropdownBorderColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="dropdownBorderFocusColour"
                        label={intl.formatMessage(
                            definedTranslations.borderFocus
                        )}
                        selectedValue={props.theme.dropdownBorderFocusColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col sm="4">
                    <ColourPicker
                        name="dropdownSelectedBackgroundColour"
                        label={intl.formatMessage(
                            definedTranslations.highlight
                        )}
                        selectedValue={
                            props.theme.dropdownSelectedBackgroundColour
                        }
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <ColourPicker
                        name="dropdownSelectedTextColour"
                        label={intl.formatMessage(
                            definedTranslations.highlightText
                        )}
                        selectedValue={props.theme.dropdownSelectedTextColour}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
                <Col sm="4">
                    <RadiusPicker
                        name="dropdownBorderRadius"
                        label={intl.formatMessage(
                            definedTranslations.cornerRadius
                        )}
                        maxValue={20}
                        selectedValue={props.theme.dropdownBorderRadius}
                        onNumberValueChanged={props.onNumberValueChanged}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

DropdownsPanel.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onNumberValueChanged: PropTypes.func.isRequired
};

export default injectIntl(DropdownsPanel);
