import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup
} from 'reactstrap';
import PropTypes from 'prop-types';

class DeleteThemeModal extends React.Component {
    render() {
        const closeBtn = (
            <button
                className="close"
                onClick={() => this.props.toggleDeleteThemeModal()}
            >
                &times;
            </button>
        );

        return (
            <Fragment>
                <Modal size={'lg'} centered isOpen={true}>
                    <ModalHeader
                        toggle={() => this.props.toggleDeleteThemeModal(true)}
                        close={closeBtn}
                    >
                        <FormattedMessage
                            id="DeleteThemeModal.DeleteThemeTitle"
                            description="Heading of the Delete Theme modal"
                            defaultMessage="Delete {themeName}"
                            values={{
                                themeName: this.props.themeNameToDelete
                            }}
                        />
                    </ModalHeader>
                    <ModalBody className="d-flex flex-column">
                        <FormGroup>
                            <FormattedMessage
                                id="DeleteThemeModal.DeleteThemeWarning"
                                description="Warning displayed when the user tries to delete a theme"
                                defaultMessage="Are you sure you would like to delete this theme?"
                                values={{
                                    themeName: this.props.themeName
                                }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            color="primary"
                            onClick={() =>
                                this.props.toggleDeleteThemeModal(true)
                            }
                        >
                            <FormattedMessage
                                id="Common.Cancel"
                                description="Standard Cancel text"
                                defaultMessage="Cancel"
                            />
                        </Button>
                        <Button
                            color="primary"
                            onClick={() =>
                                this.props.deleteThemeConfirmed(
                                    this.props.themeIdToDelete
                                )
                            }
                        >
                            <FormattedMessage
                                id="Common.Delete"
                                description="Standard Delete text"
                                defaultMessage="Delete"
                            />
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

DeleteThemeModal.propTypes = {
    themeIdToDelete: PropTypes.number,
    themeNameToDelete: PropTypes.string,
    toggleDeleteThemeModal: PropTypes.func,
    deleteThemeConfirmed: PropTypes.func
};

export default DeleteThemeModal;
