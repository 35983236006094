import React from 'react';
import { injectIntl } from 'react-intl';
import definedTranslations from './DefinedTranslations.js';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import FontPicker from './FontPicker.js';
import CornerPicker from './CornerPicker.js';
import LogoPicker from './LogoPicker.js';
import BrandingPicker from './BrandingPicker.js';
import Panel from './Panel.js';
import PanelRow from './PanelRow.js';

function SimpleGeneralPanel(props) {
    const { intl } = props;
    return (
        <Panel header="GENERAL">
            <PanelRow>
                <Col>
                    <FontPicker
                        name="overallGeneralFont"
                        label={intl.formatMessage(definedTranslations.font)}
                        selectedValue={props.theme.overallGeneralFont}
                        onValueChanged={props.onValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col>
                    <CornerPicker
                        name="overallGeneralBorderRadius"
                        label={intl.formatMessage(
                            definedTranslations.cornerRadius
                        )}
                        selectedValue={props.theme.overallGeneralBorderRadius}
                        onValueChanged={props.onNumberValueChanged}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col>
                    <LogoPicker
                        showLibraryOption={false}
                        onFileValueChanged={props.onFileValueChanged}
                        label={intl.formatMessage(definedTranslations.logo)}
                        infoText={intl.formatMessage(
                            definedTranslations.logoUploadWarning
                        )}
                        removeUploadedLogo={props.removeUploadedLogo}
                        uploadedLogo={props.theme.customLogoUrl}
                    />
                </Col>
            </PanelRow>
            <PanelRow>
                <Col>
                    <BrandingPicker
                        name="resdiaryLogo"
                        label={intl.formatMessage(
                            definedTranslations.resdiaryBranding
                        )}
                        selectedValue={props.theme.resdiaryLogo}
                        onValueChanged={props.onNumberValueChanged}
                        resDiaryLogoUrl={props.resDiaryLogoUrl}
                    />
                </Col>
            </PanelRow>
        </Panel>
    );
}

SimpleGeneralPanel.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onNumberValueChanged: PropTypes.func.isRequired,
    onFileValueChanged: PropTypes.func.isRequired
};
export default injectIntl(SimpleGeneralPanel);
