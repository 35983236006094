import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Button } from 'reactstrap';
import ColourPanel from './ColourPanel.js';
import SimpleGeneralPanel from './SimpleGeneralPanel.js';
import styles from './SimpleTheme.module.css';

function SimpleTheme(props) {
    return (
        <Form>
            <ColourPanel
                theme={props.theme}
                onValueChanged={props.onValueChanged}
            />
            <SimpleGeneralPanel
                theme={props.theme}
                resDiaryLogoUrl={props.resDiaryLogoUrl}
                onValueChanged={props.onValueChanged}
                onNumberValueChanged={props.onNumberValueChanged}
                onFileValueChanged={props.onFileValueChanged}
                removeUploadedLogo={props.removeUploadedLogo}
            />
            <div className={styles.continueBtn}>
                <Button
                    color="primary"
                    onClick={() => props.onContinueButtonClicked()}
                >
                    <FormattedMessage
                        id="Common.Continue"
                        defaultMessage="Continue"
                        description="Standard Continue text"
                    />
                </Button>
            </div>
        </Form>
    );
}

SimpleTheme.propTypes = {
    theme: PropTypes.object.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onNumberValueChanged: PropTypes.func.isRequired,
    onFileValueChanged: PropTypes.func.isRequired,
    onContinueButtonClicked: PropTypes.func.isRequired
};

export default SimpleTheme;
